import React, { useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import voucherImg from "../assets/img/card-background.png";

import {
  Col,
  Row,
  Card,
  Image,
  Dropdown,
  Button,
  Table,
} from "@themesberg/react-bootstrap";
import {
  faGlobeEurope,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import postLockVoucherType from "../api/postLockVoucherType";
import postUnlockVoucherType from "../api/postUnlockVoucherType";
import { toast } from "react-toastify";
import { RightCornerRibbon } from "react-ribbons";

export const VoucherTypesCard = (props) => {
  const postLockType = async (id) => {
    const res = await postLockVoucherType(id);
    try {
      if (res.success) {
        toast.success(res.message);
        window.location.reload();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors du verrouillage du voucher");
    }
  };

  const postUnlockType = async (id) => {
    const res = await postUnlockVoucherType(id);
    try {
      if (res.success) {
        toast.success(res.message);
        window.location.reload();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors du déverrouillage du voucher");
    }
  };
  const { value, _id, availability, createdAt, name } = props;

  const [verso, setVerso] = useState(false);

  return (
    <div
      className="buying-card"
      style={{ marginInline: "5px", marginTop: "2%", cursor: "pointer" }}
      onClick={() => setVerso(!verso)}
    >
      <RightCornerRibbon
        backgroundColor={availability ? "#81C784" : "#E57373"}
        color="#f0f0f0"
        fontFamily="Arial"
      >
        {availability ? "Actif" : "Inactif"}
      </RightCornerRibbon>
      <img
        src={voucherImg}
        alt="payqin card"
        className="voucher-buy-wrapper__img"
      />
      {!verso ? (
        <div className="buying-card__content">
          <div className="buying-card__flex">
            <div>
              <h1 className="buying-card__title">{value} € </h1>
              <h2 className="buying-card__subtitle">{name}</h2>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="buying-card__content_verso"
          style={{ color: "white", fontSize: "13px", marginTop: "5%" }}
        >
          <ul>
            <li>{availability ? "Actif" : "Inactif"}</li>
            <li>Ajouté le {moment(createdAt).format("DD-MM-YYYY")}</li>
            <div style={{ marginTop: "10%" }}>
              {availability ? (
                <Button
                  onClick={() => postLockType(_id)}
                  style={{ backgroundColor: "#E57373", border: "none" }}
                >
                  Bloquer le voucher
                </Button>
              ) : (
                <Button
                  onClick={() => postUnlockType(_id)}
                  style={{ backgroundColor: "#81C784", border: "none" }}
                >
                  Débloquer le voucher
                </Button>
              )}
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export const VoucherSimpleCard = (props) => {
  const { voucherValue } = props;
  return (
    <div className="buying-card">
      <img
        src={voucherImg}
        alt="payqin card"
        className="voucher-buy-wrapper__img"
      />

      <div className="buying-card__content">
        <div className="buying-card__flex">
          <div>
            <h1 className="buying-card__title">{voucherValue} € </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export const VoucherStatsCard = ({ vouchers }) => {
  return (
    <Card
      border="light"
      className="table-wrapper table-responsive shadow-sm"
      style={{ marginTop: "1%" }}
    >
      {vouchers && (
        <>
          <Card.Title style={{ margin: "2%" }}>Statistiques</Card.Title>
          <Card.Body className="pt-0">
            <Card.Text>
              Dates : {moment(vouchers.start).format("DD-MM-YYYY")} au{" "}
              {moment(vouchers.end).format("DD-MM-YYYY")}
            </Card.Text>
            {/* <Card.Text>
              Status :{" "}
              {vouchers.status === "open" ? (
                <span className="text-success">Actif</span>
              ) : (
                <span className="text-danger">Expiré</span>
              )}
            </Card.Text> */}
            <Card.Text>
              Type de coupon concerné :{" "}
              {vouchers.price ? `${vouchers.price} €` : ""}
            </Card.Text>
            <Table hover className="user-table align-items-center" striped>
              <thead>
                <tr>
                  <th className="border-bottom">Quantité créée/valeur </th>
                  <th className="border-bottom">Quantité achetée/valeur</th>
                  <th className="border-bottom">Quantité consommée/valeur </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {vouchers.created && vouchers.created.qty} /{" "}
                    <b>
                      {" "}
                      {vouchers.created && `${vouchers.created.amount} €`}{" "}
                    </b>
                  </td>
                  <td>
                    {vouchers.paid && vouchers.paid.qty} /{" "}
                    <b> {vouchers.paid && `${vouchers.paid.amount} €`}</b>
                  </td>
                  <td>
                    {vouchers.consumed && vouchers.consumed.qty} /{" "}
                    <b>
                      {" "}
                      {vouchers.consumed &&
                        `${vouchers.consumed.amount} €`}{" "}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </>
      )}
    </Card>
  );
};
