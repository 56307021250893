import host from "./host";
import { getToken } from "../helpers/token";

export default async function getTodayVoucher(startDate, endDate) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify({ start: startDate, end: endDate }),
    };

    const response = await fetch(
      `${host}/admins/vouchers-by-date`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
