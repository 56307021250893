import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import postInviteAdmin from "../api/postInviteAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Card } from "@themesberg/react-bootstrap";
import {
  faPlus,
  faHome,
  faPaperPlane,
  faSearch,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
} from "@themesberg/react-bootstrap";
import getAllAdmins from "../api/getAllAdmins";
import postBlockAdmin from "../api/postBlockAdmin";
import { TableRowAdmin } from "../components/Tables";

export default () => {
  const getAdmins = async () => {
    const res = await getAllAdmins();
    if (res.success) {
      setAdmins(res.admins);
    } else {
      toast.error("Impossible de charger les données des administrateurs");
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [admins, setAdmins] = useState([]);
  const handleClose = () => setShowModal(false);
  const handleChangeEmail = (e) => setEmail(e.target.value);

  const blockAdmin = async (id) => {
    try {
      const result = await postBlockAdmin(id);

      if (result.success) {
        toast.sucess(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Une erreur est survenue , réessayez plus tard");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === "") {
      return toast.error("Email obligatoire");
    }
    try {
      const result = await postInviteAdmin(email);
      if (result.success) {
        toast.success(result.message);
        setShowModal(false);
        return;
      }

      if (result.error) {
        return toast.error(result.message);
      }
    } catch (error) {
      toast.error("Une erreur est survenue, réessayez plus tard");
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Admins</Breadcrumb.Item>
          </Breadcrumb>
          <Dropdown className="btn-toolbar">
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item
                className="fw-bold"
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Ajouter
                un admin
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Rechercher ..." />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end"></Col>
        </Row>
      </div>

      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm"
        style={{ height: "500px" }}
      >
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom"># Numéro de téléphone </th>
                <th className="border-bottom">Nom complet</th>
                <th className="border-bottom">Date d'inscription</th>
                <th className="border-bottom">Actions</th>
              </tr>
            </thead>
            <tbody>
              {admins &&
                admins.map((a) => (
                  <TableRowAdmin
                    key={`${a.id}`}
                    {...a}
                    blockAdmin={blockAdmin}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Ajout d'un administrateur </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <p>Saisissez l'adresse email de l'administrateur à inviter</p>
          <Form className="mt-4" onSubmit={handleSubmit}>
            <Form.Group id="email" className="mb-4">
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
                <Form.Control
                  autoFocus
                  required
                  type="email"
                  placeholder="example@payqin.com"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </InputGroup>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100"
              formNoValidate
            >
              Envoyer l'invitation
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
