import host from "./host";
import { getToken } from "../helpers/token";

export default async function getVouchersSalesCharts() {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    };

    const response = await fetch(
      `${host}/admins/vouchers-graphics-stats`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
