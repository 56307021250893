import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Breadcrumb,
  InputGroup,
  Form,
} from "@themesberg/react-bootstrap";
import { UserCardWidget, UserProfileCardWidget } from "../components/Widgets";
import getUserDetail from "../api/getUserDetail";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { UserTransacTable } from "../components/Tables";
import ReactPaginate from "react-paginate";
import getUserTransactions from "../api/getUserTransactions";
import postSearchUserTransactions from "../api/postSearchUserTransaction";

export default () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [kyc, setKyc] = useState({});

  const getDetails = async () => {
    const res = await getUserDetail(id);
    if (res.success) {
      setUser(res.user);
      setKyc(res.verification);
    } else {
      toast.error(
        "Impossible de charger les données de l'utilisateur pour le moment , veuillez rééssayer plus tard"
      );
    }
  };

  const getTransactions = async () => {
    try {
      const res = await getUserTransactions(id, 1);
      if (res.success) {
        setTransactions(res.transactions);
        setTotalPages(res.totalPages);
      } else {
        toast.error(
          "Impossible de charger les transactions de l'utilisateur pour le moment , veuillez rééssayer plus tard"
        );
      }
    } catch (error) {
      toast.error(
        "Impossible de charger les transactions de l'utilisateur pour le moment , veuillez rééssayer plus tard"
      );
    }
  };

  const fetchTransactions = async (id, page) => {
    try {
      const resp = await getUserTransactions(id, page);
      return resp;
    } catch (error) {
      toast.error(
        "Impossible de charger les données des transactions , rééssayez plus tard"
      );
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const result = await fetchTransactions(id, currentPage);
    if (result.success) {
      setTransactions(result.transactions);
    } else {
      toast.error(
        "Impossible de charger les données des transactions , rééssayez plus tard"
      );
    }
  };

  const handleSearchUserTransac = async (data, id) => {
    if (data === "") {
      window.location.reload();
    }
    const res = await postSearchUserTransactions(data, id);
    if (res.success) {
      setTransactions(res.transactions);
      setTotalPages(res.totalPages);
    } else {
      toast.error(
        "Impossible de charger les données des transactions , rééssayez plus tard"
      );
    }
  };

  useEffect(() => {
    getDetails();
    getTransactions();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Fiche utilisateur</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              {" "}
              <UserCardWidget userProps={user} kycProps={kyc} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={8}>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm p-3"
          >
            <Card.Title>Transactions </Card.Title>
            <Card.Body className="pt-0">
              <InputGroup
                onBlur={() => handleSearchUserTransac(searchTerm, id)}
              >
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">Référence</th>
                    <th className="border-bottom">Prix du coupon</th>
                    <th className="border-bottom">Status</th>
                    <th className="border-bottom">Date de l'opération</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions &&
                    transactions.map((t) => (
                      <UserTransacTable key={`${t._id}`} {...t} />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <ReactPaginate
                previousLabel={"Précédent"}
                nextLabel={"Suivant"}
                pageCount={totalPages && Math.ceil(totalPages)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"page-item active"}
                activeLinkClassName={"page-link"}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};
