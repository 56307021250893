import host from "./host";
import { getToken } from "../helpers/token";

export default async function postInviteAdmin(email) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify({ email: email }),
    };

    const response = await fetch(`${host}/admins/invite`, requestOptions);
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
