import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Button,
  Table,
  Badge,
} from "@themesberg/react-bootstrap";
import getVoucherstype from "../api/getVouchersType";
import { toast } from "react-toastify";
import { VoucherSimpleCard, VoucherStatsCard } from "../components/Cards";
import moment from "moment";
import getCouponsStatistics from "../api/getCouponsStats";
import getCouponsStack from "../api/getCouponsStack";
import getVoucherDetails from "../api/getVoucherDetails";
import { motion } from "framer-motion";

export default () => {
  const [vouchersType, setVouchersType] = useState([]);
  const [vouchersStats, setVouchersStats] = useState([]);
  const [vouchersStack, setVouchersStack] = useState([]);
  const [voucher, setVoucher] = useState({});
  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment());
  const [status, setStatus] = useState("");
  const [price, setPrice] = useState(0);
  const [voucherPrice, setVoucherPrice] = useState(0);

  const getVoucherStacks = async () => {
    const re = await getCouponsStack();
    if (re.success) {
      setVouchersStack(re.vouchers);
    } else {
      toast.error("Impossible de charger les lots de vouchers");
    }
  };

  const getAllTypes = async () => {
    const resp = await getVoucherstype();
    if (resp.success) {
      setVouchersType(resp.vouchers);
      console.log(resp.vouchers);
    } else {
      toast.error("Impossible de charger les types de vouchers");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await getCouponsStatistics(start, end, status, price);
    if (res.success) {
      console.log(res.vouchers);
      setVouchersStats(res.vouchers);
    } else {
      toast.error("Impossible de charger les statistiques");
    }
  };

  const handleGetDetails = async (e) => {
    e.preventDefault();
    const res = await getVoucherDetails(voucherPrice);
    if (res.success) {
      setVoucher(res.voucher);
      console.log(res.voucher);
    } else {
      toast.error("Impossible de charger le détail du voucher");
    }
  };

  useEffect(() => {
    getAllTypes();
    getVoucherStacks();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-3 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Statistiques Coupons </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Row>
        <Col>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
            style={{ marginTop: "1%" }}
          >
            <Card.Title style={{ margin: "2%" }}>
              Section de filtres{" "}
            </Card.Title>
            <hr></hr>
            <Card.Body className="pt-0">
              <Form onSubmit={handleSubmit}>
                <Form.Group style={{ marginBottom: "3%" }}>
                  <Row>
                    <Col>
                      <Form.Label>Debut</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className="form-control"
                          type="date"
                          value={start}
                          onChange={(e) => setStart(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>Fin</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className="form-control"
                          type="date"
                          value={end}
                          onChange={(e) => setEnd(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col>
                    {" "}
                    <Form.Select
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    >
                      <option defaultValue>Montant des coupons</option>
                      {vouchersType &&
                        vouchersType.map((v) => (
                          <option value={v.value} key={v._id}>
                            {v.value} €{" "}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    {/* <Form.Select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={"closed"}>Expiré</option>
                      <option defaultValue value={"open"}>
                        Actif
                      </option>
                    </Form.Select> */}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    {" "}
                    <Button type="submit" style={{ width: "100%" }}>
                      Filtrer
                    </Button>{" "}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <VoucherStatsCard vouchers={vouchersStats} />
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm"
        style={{ marginTop: "2%" }}
      >
        <Card.Title style={{ margin: "2%" }}>Details des vouchers </Card.Title>
        <Card.Body className="pt-0">
          <Form onSubmit={handleGetDetails}>
            <Row>
              <Col lg={3} xl={2}>
                <Form.Select
                  value={voucherPrice}
                  onChange={(e) => setVoucherPrice(e.target.value)}
                >
                  <option defaultValue>Montant des coupons</option>
                  {vouchersType &&
                    vouchersType.map((v) => (
                      <option value={v.value} key={v._id}>
                        {v.value} €{" "}
                      </option>
                    ))}
                </Form.Select>
                <Button
                  type="submit"
                  style={{ width: "100%", marginTop: "3%" }}
                >
                  Afficher le détail
                </Button>{" "}
              </Col>
              <Col lg={3} xl={2}>
                <VoucherSimpleCard {...voucher} />
              </Col>

              <Col xl={4}>
                {voucher.all && (
                  <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                  >
                    Nombre total:{" "}
                    <Badge bg="primary">{voucher.all.number}</Badge> | Valeur
                    totale : <Badge bg="primary">{voucher.all.value} € </Badge>
                  </motion.p>
                )}
                {voucher.bought && (
                  <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                  >
                    Achetés :{" "}
                    <Badge bg="warning"> {voucher.bought.number} </Badge> |
                    Valeur totale :{" "}
                    <Badge bg="warning"> {voucher.bought.value} € </Badge>
                  </motion.p>
                )}
                {voucher.used && (
                  <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 3 }}
                  >
                    Utilisés : <Badge bg="info">{voucher.used.number}</Badge> |
                    Valeur totale :{" "}
                    <Badge bg="info"> {voucher.used.value} € </Badge>
                  </motion.p>
                )}
                {voucher.free && (
                  <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 4 }}
                  >
                    Disponible :{" "}
                    <Badge bg="success">{voucher.free.number}</Badge> | Valeur
                    totale :{" "}
                    <Badge bg="success"> {voucher.free.value} € </Badge>
                  </motion.p>
                )}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
