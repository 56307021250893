import React, { Component, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";
import "react-toastify/dist/ReactToastify.min.css";

import SigninWithCode from "./pages/utils/SiginInWithCode";
import ResetPassword from "./pages/utils/ResetPassword";
import NotFoundPage from "./pages/utils/NotFound";
import ServerError from "./pages/utils/ServerError";

import { PrivateRoute } from "./components/PrivateRoute";

import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import IdleTimerContainer from "./helpers/idleTimer";
import UserDetail from "./pages/UserDetail";
import CouponsStats from "./pages/CouponsStats";
import Admins from "./pages/Admins";
import Coupons from "./pages/Coupons";
import KycVerifications from "./pages/KycVerifications";
import Settings from "./pages/Settings";
import Signin from "./pages/utils/Signin";
import Signup from "./pages/utils/Signup";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import DashboardOverview from "./pages/dashboard/DashboardOverview";
import ForgotPassword from "./pages/utils/ForgotPassword";
import InviteAdmin from "./pages/utils/InviteAdmin";
import Lock from "./pages/utils/Lock";
import { AuthContext } from "./context/auth";
import { getToken } from "./helpers/token";
import CouponsTransactions from "./pages/CouponsTransactions";

export const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

class App extends Component {
  render() {
    return (
      <AuthContext.Provider value={getToken()}>
        <IdleTimerContainer></IdleTimerContainer>
        <HashRouter>
          <React.Suspense>
            <Switch>
              <Route exact path={Routes.Signin.path} component={Signin} />
              <Route exact path={Routes.Signup.path} component={Signup} />
              <Route
                exact
                path={Routes.SigninWithCode.path}
                component={SigninWithCode}
              />
              <Route
                exact
                path={Routes.ForgotPassword.path}
                component={ForgotPassword}
              />
              <Route
                exact
                path={Routes.ResetPassword.path}
                component={ResetPassword}
              />
              <Route exact path={Routes.Lock.path} component={Lock} />
              <Route
                exact
                path={Routes.NotFound.path}
                component={NotFoundPage}
              />
              <Route
                exact
                path={Routes.ServerError.path}
                component={ServerError}
              />

              <RouteWithSidebar
                exact
                path={Routes.Users.path}
                component={Users}
              />
              <RouteWithSidebar
                exact
                path={Routes.UserDetails.path}
                component={UserDetail}
              />

              <RouteWithSidebar
                exact
                path={Routes.KycVerifications.path}
                component={KycVerifications}
              />
              <RouteWithSidebar
                exact
                path={Routes.CouponsStats.path}
                component={CouponsStats}
              />
              <RouteWithSidebar
                exact
                path={Routes.CouponsTransactions.path}
                component={CouponsTransactions}
              />
              <RouteWithSidebar
                exact
                path={Routes.Coupons.path}
                component={Coupons}
              />
              <RouteWithSidebar
                exact
                path={Routes.Admins.path}
                component={Admins}
              />
              <RouteWithSidebar
                exact
                path={Routes.Transactions.path}
                component={Transactions}
              />
              <RouteWithSidebar
                exact
                path={Routes.Settings.path}
                component={Settings}
              />
              <RouteWithSidebar
                exact
                path={Routes.InviteAdmin.path}
                component={InviteAdmin}
              />
              <PrivateRoute exact path="/" component={DashboardOverview} />

              <Redirect to={Routes.NotFound.path} />
            </Switch>
          </React.Suspense>
        </HashRouter>
        <ToastContainer
          position={toast.POSITION.BOTTOM_CENTER}
          autoClose={4000}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </AuthContext.Provider>
    );
  }
}

export default App;
