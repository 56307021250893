export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  Coupons: { path: "/coupons" },
  CouponsStats: { path: "/coupons-stats" },
  CouponsTransactions: { path: "/coupons-transactions" },
  Transactions: { path: "/transactions" },
  UserDetails: { path: "/users/:id" },
  Users: { path: "/users" },
  KycVerifications: { path: "/kyc-verifications" },
  Admins: { path: "/admins" },
  Settings: { path: "/settings" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up/:id" },
  SigninWithCode: { path: "/code-verification" },
  InviteAdmin: { path: "/invite-admin" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
