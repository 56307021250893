import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Breadcrumb,
  Card,
  Table,
  InputGroup,
  Button,
  Badge,
} from "@themesberg/react-bootstrap";

import moment from "moment";

import { TransacTable } from "../components/Tables";
import getAllTransactions from "../api/getAllTransactions";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

export default () => {
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [transacStatus, setStatus] = useState("all");
  const [transacDate, setDate] = useState(moment());
  const [transacDateEnd, setDateEnd] = useState(moment());
  const [transacCount, setTransacCount] = useState(0);

  const getTransactions = async () => {
    try {
      const res = await getAllTransactions(
        1,
        transacStatus,
        transacDate,
        transacDateEnd
      );
      if (res.success) {
        setTransactions(res.transactions);
        setTotalPages(res.totalPages);
        setTransacCount(res.total);
      } else {
        toast.error(
          "Impossible de charger les données des transactions , rééssayez plus tard"
        );
      }
    } catch (error) {
      toast.error(
        "Impossible de charger les données des transactions , rééssayez plus tard"
      );
    }
  };

  const fetchTransactions = async (
    page,
    status,
    transacDate,
    transacDateEnd
  ) => {
    try {
      const resp = await getAllTransactions(
        page,
        status,
        transacDate,
        transacDateEnd
      );
      return resp;
    } catch (error) {
      toast.error("Impossible de charger les données des transactions");
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const result = await fetchTransactions(
      currentPage,
      transacStatus,
      transacDate,
      transacDateEnd
    );
    if (result.success) {
      setTransactions(result.transactions);
    } else {
      toast.error(
        "Impossible de charger les données des transactions par pagination"
      );
    }
  };

  const handleSubmitTransactions = async (e) => {
    e.preventDefault();
    const result = await fetchTransactions(
      1,
      transacStatus,
      transacDate,
      transacDateEnd
    );
    if (result.success) {
      setTotalPages(result.totalPages);
      setTransactions(result.transactions);
      setTransacCount(result.total);
    } else {
      toast.error(
        "Impossible de charger les données des transactions selon le filtre"
      );
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm p-3 mb-3"
      >
        <Card.Title>Filtres</Card.Title>
        <Card.Body className="pt-0">
          <form onSubmit={handleSubmitTransactions}>
            <Row>
              <Col sm={12} xl={2}>
                <Form.Group className="mb-2">
                  <Form.Select
                    id="status"
                    defaultValue={transacStatus}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="all"> Toutes les transactions </option>
                    <option value="processed">Transactions réussies</option>
                    <option value="rejected">Transactions echouées</option>
                    <option value="pending">Transactions en attente</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                du
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={transacDate}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                au
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={transacDateEnd}
                    onChange={(e) => setDateEnd(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={2}>
                <Button color="primary" type="submit">
                  Filtrer les transactions
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
      <Card border="light" className="shadow-sm p-1 mb-2">
        <Card.Header>
          <p>
            Nombre total de transactions retournées :{" "}
            <Badge bg="success">{transacCount && Number(transacCount)}</Badge>{" "}
          </p>
        </Card.Header>
      </Card>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Référence</th>
                <th className="border-bottom">Utilisateur</th>
                <th className="border-bottom">Prix du coupon</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Date de l'opération</th>
              </tr>
            </thead>
            <tbody>
              {transactions &&
                transactions.map((t) => (
                  <TransacTable key={`${t._id}`} {...t} />
                ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <ReactPaginate
            previousLabel={"Précédent"}
            nextLabel={"Suivant"}
            pageCount={totalPages && Math.ceil(totalPages)}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"page-item active"}
            activeLinkClassName={"page-link"}
          />
        </Card.Footer>
      </Card>
    </>
  );
};
