import host from "./host";
import { getToken } from "../helpers/token";

export default async function getAllKyc(page, status, start, end) {
  try {
    console.log(page, status, start, end);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    };

    const response = await fetch(
      `${host}/admins/kyc-informations/?page=${page}&status=${status}&start=${start}&end=${end}`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
