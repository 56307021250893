import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Form,
  Breadcrumb,
  Card,
  Table,
  InputGroup,
  Button,
  Badge,
  Row,
} from "@themesberg/react-bootstrap";

import { UsersTable } from "../components/Tables";
import { toast } from "react-toastify";
import getAllUsers from "../api/getAllUsers";
import ReactPaginate from "react-paginate";
import postSearchUser from "../api/postSearchUser";
import moment from "moment";

export default () => {
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [usersCount, setUsersCount] = useState(0);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const getUsers = async () => {
    try {
      const res = await getAllUsers(1, startDate, endDate);
      if (res.success) {
        setUsers(res.users);
        setTotalPages(res.totalPages);
        setUsersCount(res.total);
      } else {
        toast.error("Impossible de charger les données des utilisateurs");
      }
    } catch (error) {
      toast.error("Impossible de charger les données des utilisateurs");
    }
  };

  const fetchUsers = async (page, startDate, endDate) => {
    try {
      const resp = await getAllUsers(page, startDate, endDate);
      return resp;
    } catch (error) {
      toast.error("Impossible de charger les données des utilisateurs");
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const result = await fetchUsers(currentPage, startDate, endDate);
    if (result.success) {
      setUsers(result.users);
    } else {
      toast.error(
        "Impossible de charger les données des utilisateurs avec la pagination"
      );
    }
  };

  const handleSearchUser = async (e) => {
    e.preventDefault();
    if (searchTerm === "") {
      window.location.reload();
    }
    const res = await postSearchUser(searchTerm);
    if (res.success) {
      setUsers(res.users);
      setUsersCount(res.total);
      setTotalPages(res.totalPages);
    } else {
      toast.error(
        "Impossible de charger les données des utilisateurs par recherche"
      );
    }
  };

  const handleSubmitUsers = async (e) => {
    e.preventDefault();
    const result = await fetchUsers(1, startDate, endDate);
    if (result.success) {
      setUsers(result.users);
      setUsersCount(result.total);
      setTotalPages(result.totalPages);
    } else {
      toast.error(
        "Impossible de charger les données des utilisateurs selon le filtre de période"
      );
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Utilisateurs</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>

      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm p-3 mb-3"
      >
        <Card.Title>Recherche utilisateurs</Card.Title>
        <form onSubmit={handleSearchUser}>
          <Card.Body className="pt-0">
            <Col xs={12} md={4} lg={4} xl={4} className="mb-2">
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Card.Body>
        </form>
        <hr></hr>
        <form onSubmit={handleSubmitUsers}>
          <Card.Body>
            <Row>
              <Col sm={12} xl={2}>
                <Form.Group className="mb-2">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={"Les utilisateurs inscrits "}
                      disabled={true}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                du
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                au
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={4}>
                <Button color="primary" type="submit">
                  Rechercher
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </form>
      </Card>

      <Card border="light" className="shadow-sm p-1 mb-2">
        <Card.Header>
          <p>
            Nombre total d'utilisateurs retournés:{" "}
            <Badge bg="success">{usersCount && Number(usersCount)}</Badge>{" "}
          </p>
        </Card.Header>
      </Card>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm mb-3"
      >
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Nom complet </th>
                <th className="border-bottom">Adresse email</th>
                <th className="border-bottom">Numéro de téléphone </th>
                <th className="border-bottom">Date d'inscription</th>
                <th className="border-bottom">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((u) => <UsersTable key={`${u._id}`} {...u} />)}
            </tbody>
          </Table>
          <div className="mt-3">
            <ReactPaginate
              previousLabel={"Précédent"}
              nextLabel={"Suivant"}
              pageCount={totalPages && Math.ceil(totalPages)}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"page-item active"}
              activeLinkClassName={"page-link"}
            />
          </div>
        </Card.Body>
      </Card>

      {/* <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm p-3 mb-3"
      >
        <Card.Title>Filtres utilisateurs </Card.Title>
        <form onSubmit={handleSearchUser}>
          <Card.Body className="pt-0">
            <Row>
              <Col sm={12} xl={2}>
                <Form.Group className="mb-2">
                  <InputGroup>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={"Les utilisateurs inscrits du "}
                      readOnly={true}
                      disabled={true}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                du
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                au
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Card.Body>
        </form>
      </Card> */}
    </>
  );
};
