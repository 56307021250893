import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogged: false,
  email: "",
  fullName: "",
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    login: (state) => {
      state.isLogged = true;
    },
    logout: (state) => {
      state.isLogged = false;
      state.email = "";
      state.fullName = "";
    },
    configureEmail: (state, action) => {
      state.email = action.payload;
    },
    configureFullName: (state, action) => {
      state.fullName = action.payload;
    },
  },
});

export const { login, logout, configureEmail, configureFullName } =
  accountSlice.actions;

export const selectLogStatus = (state) => state.account.isLogged;
export const selectEmail = (state) => state.account.email;
export const selectFullName = (state) => state.account.fullName;

export default accountSlice.reducer;
