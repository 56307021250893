import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import { removeToken } from "../token";
import { useAppDispatch } from "../../app/hook";
import { logout } from "../account/accountSlice";

function IdleTimerContainer() {
  const dispatch = useAppDispatch();
  const idleTimerRef = useRef(null);
  const logout = () => {
    // dispatch(logout());
    removeToken();
    console.log("temps passé");
    window.location.replace("https://dashboard-coupon.payqin.com/#/sign-in");
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={60 * 60 * 1000}
        onIdle={logout}
      ></IdleTimer>
    </div>
  );
}

export default IdleTimerContainer;
