import React, { useState } from "react";
import postLoginwithCode from "../../api/postLoginwithCode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { setToken } from "../../helpers/token";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { useAppDispatch } from "../../app/hook";
import {
  configureEmail,
  configureFullName,
  login,
} from "../../helpers/account/accountSlice";

export default () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [code, setCode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await postLoginwithCode(code);

    if (result.error) {
      toast.error(result.message);
    }

    if (result.success) {
      setToken(result.code);
      dispatch(login());
      dispatch(configureEmail(result.email));
      dispatch(configureFullName(result.name));
      toast.success(result.message);
      history.push("/");
    }
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Validation du code</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label style={{ textAlign: "center" }}>
                      Votre code de vérification{" "}
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faLock} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="string"
                        placeholder="** ** ** ** ** ** ** **"
                        onChange={handleCodeChange}
                        value={code}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    formNoValidate
                  >
                    Connexion
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
