import host from "./host";

export default async function postLoginwithCode(code) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify({ code: code }),
    };

    const response = await fetch(`${host}/admins/login`, requestOptions);
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
