import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Table,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import postGenerateVoucher from "../api/postGenerateCoupons";
import postCreateVoucherType from "../api/postCreateVoucherType";
import getVoucherstype from "../api/getVouchersType";
import getCouponsHistory from "../api/getCouponsHistory";
import { toast } from "react-toastify";
import { VoucherTypesCard } from "../components/Cards";
import { TableCouponsHistory, VoucherStackTable } from "../components/Tables";
import getCouponsStack from "../api/getCouponsStack";
import ReactPaginate from "react-paginate";

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalSec, setShowModalSec] = useState(false);
  const [voucherValue, setValue] = useState("");
  const [vouchersType, setVouchersType] = useState([]);
  const [vouchersHistory, setVouchersHistory] = useState([]);
  const [vouchersStack, setVouchersStack] = useState([]);
  const [qty, setQty] = useState("");
  const [amount, setAmount] = useState("");
  const [totalPages, setTotalPages] = useState(10);

  const getVoucherStacks = async () => {
    const re = await getCouponsStack();
    if (re.success) {
      setVouchersStack(re.vouchers);
    } else {
      toast.error("Impossible de charger les lots de vouchers");
    }
  };

  const getAllHistory = async () => {
    const response = await getCouponsHistory(1);
    if (response.success) {
      setVouchersHistory(response.history);
      setTotalPages(response.totalPages);
    } else {
      toast.error("Impossible de charger l'historique");
    }
  };

  const getAllTypes = async () => {
    const resp = await getVoucherstype();
    if (resp.success) {
      setVouchersType(resp.vouchers);
      console.log(resp.vouchers);
    } else {
      toast.error("Impossible de charger les types de vouchers");
    }
  };

  const fetchHistory = async (page) => {
    try {
      const resp = await getCouponsHistory(page);
      return resp;
    } catch (error) {
      toast.error("Impossible de charger l'historique");
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const result = await fetchHistory(currentPage);
    console.log(result);
    if (result.success) {
      setVouchersHistory(result.history);
    } else {
      toast.error("Impossible de charger l'historique");
    }
  };

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleChangeQty = (e) => {
    setQty(e.target.value);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const handleCloseSec = () => {
    setShowModalSec(false);
  };

  const handleGenerateVouchers = async (e) => {
    e.preventDefault();
    try {
      const result = await postGenerateVoucher(amount, qty);
      if (result.success) {
        toast.success(result.message);
        setShowModal(false);
        // window.location.reload();
        getAllHistory();
        getAllTypes();
        getVoucherStacks();
      } else {
        toast.error("Une erreur est survenue, réessayez plus tard");
      }
    } catch (error) {
      toast.error("Une erreur est survenue, réessayez plus tard");
    }
  };

  const handleCreateVoucherType = async (e) => {
    e.preventDefault();
    try {
      const res = await postCreateVoucherType(voucherValue);
      if (res.success) {
        toast.success(res.message);
        setShowModalSec(false);
        // window.location.reload();
        getAllHistory();
        getAllTypes();
        getVoucherStacks();
      }
      if (res.error) {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Une erreur est survenue, réessayez plus tard");
    }
  };

  useEffect(() => {
    getAllTypes();
    getVoucherStacks();
    getAllHistory();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Coupons</Breadcrumb.Item>
          </Breadcrumb>
          <Dropdown className="btn-toolbar">
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item
                className="fw-bold"
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon icon={faTicketAlt} className="me-2" /> Générer
                des coupons
              </Dropdown.Item>
              <Dropdown.Item
                className="fw-bold"
                onClick={() => setShowModalSec(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Ajouter un
                type de coupon
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {vouchersType &&
        vouchersType.map((v) => <VoucherTypesCard key={v._id} {...v} />)}

      {/* <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm"
        style={{ marginTop: "2%" }}
      >
        <Card.Title style={{ margin: "2%" }}>Details des vouchers </Card.Title>
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center" striped>
            <thead>
              <tr>
                <th className="border-bottom">Valeur </th>
                <th className="border-bottom">Valeur totale </th>
                <th className="border-bottom">Quantité</th>
              </tr>
            </thead>
            <tbody>
              {vouchersStack &&
                vouchersStack.map((s) => (
                  <VoucherStackTable key={s._id} {...s} />
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card> */}

      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm"
        style={{ marginTop: "5%" }}
      >
        <Card.Title style={{ margin: "2%" }}>Historique</Card.Title>
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Date </th>
                <th className="border-bottom">Libellé </th>
                <th className="border-bottom">Administrateur</th>
              </tr>
            </thead>
            <tbody>
              {vouchersHistory &&
                vouchersHistory.map((v) => (
                  <TableCouponsHistory key={`${v._id}`} {...v} />
                ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <ReactPaginate
            previousLabel={"Précédent"}
            nextLabel={"Suivant"}
            pageCount={totalPages && Math.ceil(totalPages)}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"page-item active"}
            activeLinkClassName={"page-link"}
          />
        </Card.Footer>
      </Card>

      <Modal
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={handleClose}
        size="md"
      >
        <Modal.Header>
          <Modal.Title className="h6">Création de coupons</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <p>Choisissez le montant et la quantité de coupons à générer</p>
          <Form className="mt-1" onSubmit={handleGenerateVouchers}>
            <Form.Select value={amount} onChange={handleChangeAmount}>
              <option defaultValue>Selectionnez le montant du coupon</option>
              {vouchersType &&
                vouchersType.map((v) => (
                  <option value={v.value} key={v._id}>
                    {v.value} €{" "}
                  </option>
                ))}
            </Form.Select>

            <Form.Label className="mt-3">Quantité de coupons </Form.Label>
            <Form.Group id="qty" className="mb-4">
              <InputGroup>
                <InputGroup.Text></InputGroup.Text>
                <Form.Control
                  autoFocus
                  required
                  type="number"
                  min={1}
                  value={qty}
                  onChange={handleChangeQty}
                />
              </InputGroup>
            </Form.Group>

            <Button
              variant="tertiary"
              type="submit"
              className="w-100"
              formNoValidate
            >
              Générer les {qty} coupons
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="text-white ms-auto"
            onClick={handleClose}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showModalSec}
        onHide={handleCloseSec}
        size="md"
      >
        <Modal.Header>
          <Modal.Title className="h6">Création de coupons</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseSec} />
        </Modal.Header>
        <Modal.Body>
          <p>Choisissez la valeur du coupon à créer</p>
          <Form className="mt-3" onSubmit={handleCreateVoucherType}>
            <Form.Label className="mt-3">Montant de coupon </Form.Label>
            <Form.Group id="qty" className="mb-4">
              <InputGroup>
                <InputGroup.Text></InputGroup.Text>
                <Form.Control
                  autoFocus
                  required
                  type="number"
                  min={50}
                  step={50}
                  value={voucherValue}
                  onChange={handleChangeValue}
                />
              </InputGroup>
            </Form.Group>

            <Button
              variant="info"
              type="submit"
              className="w-100"
              formNoValidate
            >
              Créer le coupon de {voucherValue} €
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="text-white ms-auto"
            onClick={handleCloseSec}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
