import host from "./host";

export default async function postRegister(name, password, phone, id) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify({
        fullName: name,
        password: password,
        phone: phone ? phone : "",
      }),
    };

    const response = await fetch(
      `${host}/admins/register/${id}`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
