import host from "./host";
import { getToken } from "../helpers/token";

export default async function getCouponsStatistics(
  startDate,
  endDate,
  status,
  price
) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify({
        start: startDate,
        end: endDate,
        status: status,
        price,
      }),
    };

    const response = await fetch(
      `${host}/admins/vouchers-stats`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
