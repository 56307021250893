import host from "./host";
import { getToken } from "../helpers/token";

export default async function getAllVouchersTransactions({
  page,
  type,
  operation,
  start,
  end,
}) {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    };

    const response = await fetch(
      `${host}/admins/all-vouchers-transactions/?page=${page}&type=${type}&operation=${operation}&start=${start}&end=${end}`,
      requestOptions
    );
    return response.json({ success: true });
  } catch (error) {
    return console.log(error);
  }
}
