import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Form,
  Breadcrumb,
  Card,
  Table,
  InputGroup,
  Button,
  Badge,
} from "@themesberg/react-bootstrap";
import {
  faPlus,
  faTicketAlt,
  faShoppingBasket,
  faArrowDown,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import { VoucherActivityTable } from "../components/Tables";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import getAllVouchersTransactions from "../api/getAllVouchers";
import getVoucherstype from "../api/getVouchersType";
import { VoucherStockCard } from "../components/Widgets";

export default () => {
  const [cardLoading, setCardLoading] = useState(false);
  const [transactionLoading, setTransactionsLoading] = useState(false);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [activities, setActivities] = useState({});
  const [vouchersType, setVouchersType] = useState([]);
  const [voucherChoice, setVoucherChoice] = useState("");
  const [activityType, setActivityType] = useState("");

  const [activityCount, setActivityCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [voucherActualStock, setVoucherActualStock] = useState(0);
  const [voucherCreatedStock, setVoucherCreatedStock] = useState(0);
  const [voucherSoldStock, setVoucherSoldStock] = useState(0);
  const [voucherUsedStock, setVoucherUsedStock] = useState(0);

  const [voucherActualStockValue, setVoucherActualStockValue] = useState(0);
  const [voucherCreatedStockValue, setVoucherCreatedStockValue] = useState(0);
  const [voucherSoldStockValue, setVoucherSoldStockValue] = useState(0);
  const [voucherUsedStockValue, setVoucherUsedStockValue] = useState(0);

  const getAllVouchersType = async () => {
    const result = await getVoucherstype();

    if (result.success) {
      setVouchersType(result.vouchers);
    } else {
      toast.error("Impossible de charger les types de vouchers pour le moment");
    }
  };

  const getAllActivities = async () => {
    try {
      const res = await getAllVouchersTransactions({
        page: 1,
        type: "",
        operation: "",
        start: startDate,
        end: endDate,
      });
      if (res.success) {
        setActivities(res.activities);
        setTotalPages(res.totalPages);
        setActivityCount(res.total);

        setVoucherActualStock(
          res.activities.couponsActual && res.activities.couponsActual.count
        );

        setVoucherActualStockValue(
          res.activities.couponsActual && res.activities.couponsActual.value
        );

        setVoucherCreatedStock(
          res.activities.couponsCreated && res.activities.couponsCreated.count
        );
        setVoucherCreatedStockValue(
          res.activities.couponsCreated && res.activities.couponsCreated.value
        );

        setVoucherSoldStock(
          res.activities.couponsSold && res.activities.couponsSold.count
        );
        setVoucherSoldStockValue(
          res.activities.couponsSold && res.activities.couponsSold.value
        );

        setVoucherUsedStock(
          res.activities.couponsSold && res.activities.couponsUsed.count
        );
        setVoucherUsedStockValue(
          res.activities.couponsSold && res.activities.couponsUsed.value
        );
      } else {
        toast.error("Impossible de charger les données des vouchers");
      }
    } catch (error) {
      toast.error("Impossible de charger les données liées aux vouchers");
    }
  };

  const fetchActivities = async (page, type, operation, start, end) => {
    try {
      const resp = await getAllVouchersTransactions({
        page,
        type,
        operation,
        start,
        end,
      });
      return resp;
    } catch (error) {
      toast.error(
        "Impossible de charger les données des opérations des vouchers "
      );
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const result = await fetchActivities(
      currentPage,
      voucherChoice,
      activityType,
      startDate,
      endDate
    );
    if (result.success) {
      setActivities(result.activities);
    } else {
      toast.error(
        "Impossible de charger les données des opérations par pagination"
      );
    }
  };

  const handleSubmitVoucherForm = async (e) => {
    e.preventDefault();
    const result = await fetchActivities(
      1,
      voucherChoice,
      activityType,
      startDate,
      endDate
    );
    if (result.success) {
      setTotalPages(result.totalPages);
      setActivityCount(result.total);
      setActivities(result.activities);
      setVoucherActualStock(
        result.activities.couponsActual && result.activities.couponsActual.count
      );
      setVoucherActualStockValue(
        result.activities.couponsActual && result.activities.couponsActual.value
      );
      setVoucherCreatedStock(
        result.activities.couponsCreated &&
          result.activities.couponsCreated.count
      );
      setVoucherCreatedStockValue(
        result.activities.couponsCreated &&
          result.activities.couponsCreated.value
      );
      setVoucherSoldStock(
        result.activities.couponsSold && result.activities.couponsSold.count
      );
      setVoucherSoldStockValue(
        result.activities.couponsSold && result.activities.couponsSold.value
      );
      setVoucherUsedStock(
        result.activities.couponsSold && result.activities.couponsUsed.count
      );
      setVoucherUsedStockValue(
        result.activities.couponsSold && result.activities.couponsUsed.value
      );
    } else {
      toast.error(
        "Impossible de charger les données des opérations par le formulaire"
      );
    }
  };

  useEffect(() => {
    getAllActivities();
    getAllVouchersType();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Transactions , activités et stock coupons{" "}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm p-3 mb-3"
      >
        <Card.Title>Filtres</Card.Title>
        <Card.Body className="pt-0">
          <form onSubmit={handleSubmitVoucherForm}>
            <Row>
              <Col sm={12} xl={2}>
                <Form.Group className="mb-2">
                  <Form.Select
                    id="operationType"
                    defaultValue={activityType}
                    onChange={(e) => setActivityType(e.target.value)}
                    disabled={true}
                  >
                    <option value="all"> Toutes les Transactions </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={12} xl={2}>
                <Form.Group className="mb-2">
                  <Form.Select
                    id="voucherType"
                    value={voucherChoice}
                    onChange={(e) => setVoucherChoice(e.target.value)}
                  >
                    <option value="all">Tous les vouchers</option>
                    {vouchersType &&
                      vouchersType.map((v) => (
                        <option value={v.value} key={v._id}>
                          {v.value} €{" "}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                du
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                au
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={2}>
                <Button color="primary" type="submit">
                  Filtrer selon les critères
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
      <Card border="light" className="shadow-sm p-1 mb-2">
        <Card.Header>
          <p>
            Nombre total de lignes retournées :{" "}
            <Badge bg="success">{activityCount && Number(activityCount)}</Badge>{" "}
          </p>
        </Card.Header>
      </Card>
      <Row>
        <Col className="mb-3">
          <VoucherStockCard
            iconImg={faTicketAlt}
            count={voucherActualStock && Number(voucherActualStock)}
            title={"Stock disponible "}
            amount={voucherActualStockValue && Number(voucherActualStockValue)}
          />
        </Col>
        <Col className="mb-3">
          <VoucherStockCard
            iconImg={faPlus}
            count={voucherCreatedStock && Number(voucherCreatedStock)}
            title={"Quantitée créée"}
            amount={
              voucherCreatedStockValue && Number(voucherCreatedStockValue)
            }
          />
        </Col>
        <Col className="mb-3">
          <VoucherStockCard
            iconImg={faShoppingBasket}
            count={voucherSoldStock && Number(voucherSoldStock)}
            title={" Quantitée vendue "}
            amount={voucherSoldStockValue && Number(voucherSoldStockValue)}
          />
        </Col>
        <Col className="mb-3">
          <VoucherStockCard
            iconImg={faArrowDown}
            count={voucherUsedStock && Number(voucherUsedStock)}
            title={"Quantitée utilisée "}
            amount={voucherUsedStockValue && Number(voucherUsedStockValue)}
          />
        </Col>
      </Row>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Type d'opération</th>
                <th className="border-bottom">Date</th>
                <th className="border-bottom">Coupon</th>
                <th className="border-bottom">Utilisateur </th>
                <th className="border-bottom">Référence / Note </th>
                <th className="border-bottom">Statut de l'opération </th>
              </tr>
            </thead>
            <tbody>
              {activities &&
                activities.operations &&
                activities.operations.map((activity) => (
                  <VoucherActivityTable key={`${activity._id}`} {...activity} />
                ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <ReactPaginate
            previousLabel={"Précédent"}
            nextLabel={"Suivant"}
            pageCount={totalPages && Math.ceil(totalPages)}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"page-item active"}
            activeLinkClassName={"page-link"}
          />
        </Card.Footer>
      </Card>
    </>
  );
};
