import { Navbar } from "@themesberg/react-bootstrap";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "../helpers/token";
import React from "react";
import { RouteWithSidebar } from "../App";
export { PrivateRoute };

function PrivateRoute({ component: Component, ...rest }) {
  const auth = getToken();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth) {
          return (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          );
        }

        return <RouteWithSidebar component={Component} />;
      }}
    />
  );
}
