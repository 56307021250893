import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Breadcrumb,
  Card,
  Table,
  InputGroup,
  Button,
  Row,
  Form,
  Badge,
} from "@themesberg/react-bootstrap";

import { KYCTable } from "../components/Tables";
import { toast } from "react-toastify";
import getAllKyc from "../api/getAllKycData";
import moment from "moment";
import ReactPaginate from "react-paginate";

export default () => {
  const [kycs, setKycs] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [totalPages, setTotalPages] = useState(0);
  const [kycCount, setKycCount] = useState(0);
  const [kycStatus, setKycStatus] = useState("PROCESSING");

  const getKycs = async () => {
    try {
      const res = await getAllKyc(1, kycStatus, startDate, endDate);

      if (res.success) {
        setKycs(res.data);
      } else {
        toast.error("Impossible de charger les données KYC des utilisateurs 1");
      }
    } catch (error) {
      toast.error("Impossible de charger les données KYC des utilisateurs 2");
    }
  };

  const fetchKycs = async (page, kycStatus, startDate, endDate) => {
    try {
      const resp = await getAllKyc(page, kycStatus, startDate, endDate);
      return resp;
    } catch (error) {
      toast.error("Impossible de charger les données KYC des utilisateurs 3");
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const result = await fetchKycs(currentPage, kycStatus, startDate, endDate);
    if (result.success) {
      setKycs(result.data);
    } else {
      toast.error(
        "Impossible de charger les données KYC des utilisateurs par pagination"
      );
    }
  };

  const handleSubmitKYCSearch = async (e) => {
    e.preventDefault();
    console.log({
      "status kyc": kycStatus,
      "debut de demandes": startDate,
      "fin de date": endDate,
    });
    const result = await fetchKycs(1, kycStatus, startDate, endDate);
    if (result.success) {
      setTotalPages(result.totalPages);
      setKycs(result.data);
      setKycCount(result.total);
    } else {
      toast.error(
        "Impossible de charger les données KYC des utilisateurs selon le filtre"
      );
    }
  };

  useEffect(() => {
    getKycs();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Demandes de verifications utilisateurs
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm p-3 mb-3"
      >
        <Card.Title>Filtres</Card.Title>
        <Card.Body className="pt-0">
          <form onSubmit={handleSubmitKYCSearch}>
            <Row>
              <Col sm={12} xl={2}>
                <Form.Group className="mb-2">
                  <Form.Select
                    id="status"
                    defaultValue={kycStatus}
                    onChange={(e) => setKycStatus(e.target.value)}
                  >
                    <option value="PROCESSING">Demandes en attente</option>
                    <option value="APPROVED">Demandes validées</option>
                    <option value="REJECTED">Demandes refusées</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                du
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={1} className="text-center mt-2">
                au
              </Col>
              <Col sm={12} xl={2}>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} xl={2}>
                <Button color="primary" type="submit">
                  Filtrer les demandes
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
      <Card border="light" className="shadow-sm p-1 mb-2">
        <Card.Header>
          <p>
            Nombre total de demandes retournées :{" "}
            <Badge bg="success">{kycCount && Number(kycCount)}</Badge>{" "}
          </p>
        </Card.Header>
      </Card>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Prénom</th>
                <th className="border-bottom">Nom</th>
                <th className="border-bottom">Date de naissance</th>
                <th className="border-bottom">Pays</th>
                <th className="border-bottom">Ville</th>
                <th className="border-bottom">Addresse</th>
                <th className="border-bottom">Code postal</th>
                <th className="border-bottom">Numéro de carte</th>
                <th className="border-bottom">Image</th>
                <th className="border-bottom">Image carte</th>
                <th className="border-bottom">Actions</th>
              </tr>
            </thead>
            <tbody>
              {kycs && kycs.map((u) => <KYCTable key={`${u._id}`} {...u} />)}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <ReactPaginate
            previousLabel={"Précédent"}
            nextLabel={"Suivant"}
            pageCount={totalPages && Math.ceil(totalPages)}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"page-item active"}
            activeLinkClassName={"page-link"}
          />
        </Card.Footer>
      </Card>
    </>
  );
};
