import host from "./host";
import { getToken } from "../helpers/token";

export default async function postKycVerify(approve, id, rejectReason) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify({ approve: approve, rejectReason: rejectReason }),
    };

    const response = await fetch(
      `${host}/admins/verify-kyc-informations/${id}`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
