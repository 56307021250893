import host from "./host";
import { getToken } from "../helpers/token";

export default async function postGenerateVoucher(amount, qty) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify({ total: qty, value: amount }),
    };

    const response = await fetch(
      `${host}/admins/generate-voucher`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    return console.log(error);
  }
}
