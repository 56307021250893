import React, { useEffect, useState } from "react";
import {
  faCashRegister,
  faChartLine,
  faTicketAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Form,
  InputGroup,
  Row,
  Button,
} from "@themesberg/react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";

import {
  CounterWidget,
  SalesValueWidget,
  SalesValueWidgetPhone,
  VoucherWidget,
  VoucherTransactionWidget,
  ClientsWidget,
  AdminCounterWidget,
  RegistrationValueWidget,
} from "../../components/Widgets";
import getAllAdmins from "../../api/getAllAdmins";
import getTodayRegistrations from "../../api/getTodayRegistrations";
import getTodayVoucher from "../../api/getTodayVoucher";
import getCouponsRevenueMonthly from "../../api/getCouponsMonthly";
import getUsersMonthly from "../../api/getUsersMonthly";
import getVouchersSalesCharts from "../../api/getVouchersSalesChart";
import getRegistrationCharts from "../../api/getRegistrationsChart";
import getDataByMonth from "../../api/getDataPerMonth";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default () => {
  const [users, setUsers] = useState();
  const [transactions, setTransactions] = useState([]);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [sales, setSales] = useState(0);
  const [yesterdaySales, setYesterdaySales] = useState(0);
  const [revenue, setRevenue] = useState({});
  const [customers, setCustomers] = useState({});
  const [admins, setAdmins] = useState([]);
  const [transacDate, setDate] = useState(moment());
  const [vouchersSales, setVouchersSales] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [yesterdayRegistrations, setYesterdayRegistrations] = useState(0);
  const [period, setPeriod] = useState("");

  const getAdmins = async () => {
    const res = await getAllAdmins();
    if (res.success) {
      setAdmins(res.admins);
    } else {
      toast.error("Impossible de charger les données des administrateurs");
    }
  };

  const getRevenue = async () => {
    const res = await getCouponsRevenueMonthly();
    if (res.success) {
      setRevenue(res);
    } else {
      toast.error("Impossible de charger les données du revenue mensuel");
    }
  };

  const getVouchersCharts = async () => {
    const result = await getVouchersSalesCharts();
    if (result.success) {
      setVouchersSales(result.salesArray);
    } else {
      toast.error("Impossible de charger les données du graphique des ventes");
    }
  };

  const getRegistrations = async () => {
    const result = await getRegistrationCharts();

    if (result.success) {
      setRegistrations(result.registrationsArray);
    } else {
      toast.error(
        "Impossible de charger les données du graphique des utilisateurs"
      );
    }
  };

  const getCustomers = async () => {
    const res = await getUsersMonthly();
    if (res.success) {
      setCustomers(res);
    } else {
      toast.error("Impossible de charger les données des clients mensuels");
    }
  };

  const getTransactions = async () => {
    const res = await getTodayVoucher();

    if (res.success) {
      setTransactions(res.vouchers);
      setTransactionsCount(res.vouchers && res.vouchers.length);
      setSales(res.sales);
      setYesterdaySales(res.percentage);
    } else {
      toast.error(
        "Impossible de charger les données des transactions sur la période"
      );
    }
  };

  const getUsers = async () => {
    const res = await getTodayRegistrations();
    if (res.success) {
      setUsers(res.users);
      setYesterdayRegistrations(res.percentage);
    } else {
      toast.error(
        "Impossible de charger les utilisateurs inscrits sur la période"
      );
    }
  };

  const fetchData = async () => {
    try {
      const res = await getTodayVoucher(transacDate, transacDate);
      const resp = await getTodayRegistrations(transacDate, transacDate);
      if (res.success) {
        setTransactions(res.vouchers);
        setTransactionsCount(res.vouchers && res.vouchers.length);
        setSales(res.sales);
      } else {
        toast.error(
          "Impossible de charger les données des transactions sur la période"
        );
      }

      if (resp.success) {
        setUsers(resp.users);
      } else {
        toast.error(
          "Impossible de charger les utilisateurs inscrits sur la période"
        );
      }
    } catch (error) {
      toast.error("Impossible de charger les données sur la période");
    }
  };

  const fetchDataPerMonth = async (e) => {
    e.preventDefault();
    if (!period || period === "") {
      return toast.error(
        "Veuillez sélectionner une période avant la recherche"
      );
    }
    const resp = await getDataByMonth(period);
    if (resp.success) {
      setRevenue(resp.revenue);
      setCustomers(resp.users);
    } else {
      toast.error("Impossible de  recuperer les données par mois");
    }
  };

  useEffect(() => {
    getTransactions();
    getUsers();
    getCustomers();
    getRevenue();
    getAdmins();
    getVouchersCharts();
    getRegistrations();
  }, []);
  return (
    <>
      <Row>
        <Col>
          <Form.Group
            id="date_dashboard"
            style={{ marginBottom: "3%", maxWidth: "70%" }}
          >
            <InputGroup onBlur={fetchData}>
              <Form.Control
                className="form-control"
                type="date"
                value={transacDate}
                onChange={(e) => setDate(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>

      {/* Tableau des valeurs de base  */}
      <Row className="justify-content-md-center">
        <Col xs={12} lg={4} className="mb-3">
          <Link to="/coupons-transactions">
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.6 }}
              style={{ cursor: "pointer" }}
            >
              <VoucherWidget
                vouchers={transactionsCount}
                date={transacDate}
              ></VoucherWidget>
            </motion.div>
          </Link>
        </Col>
        <Col xs={12} lg={4} className="mb-3">
          <Link to="/transactions">
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.6 }}
              style={{ cursor: "pointer" }}
            >
              <VoucherTransactionWidget
                transactions={transactions.length}
                date={transacDate}
              ></VoucherTransactionWidget>
            </motion.div>
          </Link>
        </Col>
        <Col xs={12} lg={4} className="mb-3">
          <Link to="/users">
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.6 }}
              style={{ cursor: "pointer" }}
            >
              <ClientsWidget clients={users} date={transacDate}></ClientsWidget>
            </motion.div>
          </Link>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        {/*  Graphes tableau des ventes  */}
        <Col xs={12} lg={6} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Valeur des ventes"
            value={`${sales && sales.toFixed(2)}`}
            percentage={yesterdaySales}
            salesGraphcics={vouchersSales}
          />
        </Col>
        {/*  Graphe des clients inscrits  */}
        <Col xs={12} lg={6} className="mb-4 d-none d-sm-block">
          <RegistrationValueWidget
            title="Courbe des clients inscrits"
            value={users && Number(users)}
            percentage={yesterdayRegistrations}
            registrationsGraphics={registrations}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Valeur des ventes"
            value={`${sales && sales.toFixed(2)}`}
            percentage={yesterdaySales}
            salesGraphcics={vouchersSales}
          />
        </Col>
      </Row>

      {/*  Sections de tri des données utiles  */}

      <Row className="mb-3">
        <Col>
          <Form.Group id="month_data">
            <InputGroup>
              <Form.Control
                className="form-control"
                type="month"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <div className="d-row">
            <Button variant="info" type="submit" onClick={fetchDataPerMonth}>
              Afficher les données par mois
            </Button>
            <Button
              variant="secondary"
              type="submit"
              style={{ marginLeft: "2%" }}
              onClick={() => console.log("bonjour le monde")}
            >
              Exporter les données
            </Button>
          </div>
        </Col>
      </Row>

      {/*  Graphes des données utiles  */}
      <Row>
        <Col xs={12} lg={3} className="mb-4">
          {customers && (
            <CounterWidget
              category="Clients inscrits"
              title={customers.users}
              period={`${moment(customers.startDate).format(
                "MMM DD"
              )} - ${moment(customers.endDate).format("MMM DD")}`}
              percentage={
                customers.percentage === null ? 0 : customers.percentage
              }
              icon={faChartLine}
              iconColor="shape-secondary"
            />
          )}
        </Col>
        <Col xs={12} lg={3} className="mb-4">
          {revenue && (
            <CounterWidget
              category="Coupons vendus"
              title={revenue.qty}
              period={`${moment(revenue.startDate).format("MMM DD")} - ${moment(
                revenue.endDate
              ).format("MMM DD")}`}
              percentage={
                revenue.percentageVoucher === null
                  ? 0
                  : revenue.percentageVoucher
              }
              icon={faTicketAlt}
              iconColor="shape-tertiary"
            />
          )}
        </Col>
        <Col xs={12} lg={3} className="mb-4">
          {revenue && (
            <CounterWidget
              category="Revenu"
              title={`$ ${revenue.revenue && revenue.revenue.toFixed(2)} `}
              period={`${moment(revenue.startDate).format("MMM DD")} - ${moment(
                revenue.endDate
              ).format("MMM DD")}`}
              percentage={revenue.percentage === null ? 0 : revenue.percentage}
              icon={faCashRegister}
              iconColor="shape-tertiary"
            />
          )}
        </Col>
        <Col xs={12} lg={3} className="mb-4">
          {admins && (
            <AdminCounterWidget
              category="Membres de l'équipe"
              title={admins.length}
              icon={faUserShield}
              iconColor="shape-tertiary"
            />
          )}
        </Col>
      </Row>
    </>
  );
};
