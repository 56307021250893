import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import BgImage from "../../assets/img/illustrations/signin.svg";
import postLogin from "../../api/postLogin";

export default () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      return toast.error("Email obligatoire !");
    }

    if (password === "") {
      return toast.error("Mot de passe obligatoire !");
    }
    try {
      const result = await postLogin(email, password);
      if (result.success) {
        toast.success(result.message);
        history.push("/code-verification");
      }

      if (result.error) {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Une erreur est survenue , réessayez plus tartd");
    }
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Dashboard Coupon</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Votre email de connexion</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@payqin.com"
                        value={email}
                        onChange={handleChangeEmail}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Votre mot de passe</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type={showPass ? "text" : "password"}
                          placeholder="**********************"
                          value={password}
                          onChange={handleChangePassword}
                        />
                        <InputGroup.Text style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => setShowPass(!showPass)}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    {/* <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Se souvenir de moi
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">
                        Mot de passe oublié ?
                      </Card.Link>
                    </div> */}
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    formNoValidate
                  >
                    Verification
                  </Button>
                </Form>
                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Pas encore enregistré ? Demandez une invitation à un Admin
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
